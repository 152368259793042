/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  InboxIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { graphql, Link } from "gatsby";

const planYourVisit = [
  {
    name: "FAQ",
    description: "Frequently Asked Questions about Pearl Harbor, logistics and useful facts.",
    href: "/plan-your-pearl-harbor-visit/faq",
    icon: SupportIcon,
  },
  {
    name: "COVID-19",
    description: "Information regarding ways the COVID-19 pandemic may impact your visit to Pearl Harbor",
    href: "/plan-your-pearl-harbor-visit/covid-19",
    icon: SupportIcon,
  },
];

const sites = [
  {
    name: "USS Arizona & Arizona Memorial",
    description: "The Arizona Memorial is the final resting place of 1,177 Sailors and Marines killed during the attack at Pearl Harbor.",
    href: "/sites-and-history/uss-arizona-memorial",
    icon: SupportIcon,
  },
  {
    name: "USS Bowfin Submarine",
    description: "Visit the USS Bowfin Submarine, the Submarine Memorial and the Submarine Museum.",
    href: "/sites-and-history/uss-bowfin-submarine",
    icon: SupportIcon,
  },
  {
    name: "USS Missouri",
    description: 'The "Mighty Mo" was the last Iowa-class battleship but by the United States, launched in 1944.',
    href: "/sites-and-history/uss-missouri-battleship",
    icon: SupportIcon,
  },
  {
    name: "Pearl Harbor Aviation Museum",
    description:
      "The Pearl Harbor Aviation Museum is a non-profit museum featuring a wide variety of aircraft and aviation exhibits associated with the Attack on Pearl Harbor and WWII.",
    href: "/sites-and-history/aviation-museum",
    icon: SupportIcon,
  },
];
const callsToAction = [
  { name: "Book Now", href: "/book-now", icon: CalendarIcon },
  { name: "Contact", href: "tel:808-780-1253", icon: PhoneIcon },
  { name: "Email", href: "mailto:keaweadventures@gmail.com", icon: InboxIcon },
];
const resources = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DropDown = ({ open }) => {
  return (
    <ChevronDownIcon
      className={classNames(
        open ? "text-gray-600" : "text-gray-400",
        "ml-2 h-5 w-5 group-hover:text-gray-500 hover:bg-us-red hover:border-2 hover:border-us-blue hover:text-white rounded-full"
      )}
      aria-hidden="true"
    />
  );
};

export default function Example() {
  return (
    <Popover className="relative bg-white">
      <nav>
        <div className="flex justify-between items-center p-4 max-w-screen-lg gap-6 m-auto lg:px-0">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Pearl Harbor Experience</span>
              <p className="nav-title test">
                <div className="custom-underline">Pearl Harbor</div>
                <div className="custom-underline">Experience</div>
              </p>
            </Link>
          </div>
          <div className="md:hidden">
            <Popover.Button className="bg-white rounded-sm p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-us-red">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group className="hidden md:flex space-x-6 items-center">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-sm inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-us-red"
                    )}
                  >
                    <Link to="/sites-and-history" className="custom-underline text-base font-medium text-gray-500 hover:text-gray-900">
                      Sites & History
                    </Link>
                    <DropDown open={open} />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-4 bg-white px-5 py-3 sm:gap-8 sm:p-4">
                          {sites.map((item) => (
                            <Link to={item.href} key={item.name} className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                              <item.icon className="flex-shrink-0 h-6 w-6 text-us-blue" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-6 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <Link to={item.href} className="-m-3 p-3 flex items-center rounded-sm text-base font-medium text-gray-900 hover:bg-gray-100">
                                <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-sm inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-us-red"
                    )}
                  >
                    <Link to="/plan-your-pearl-harbor-visit" className="custom-underline text-base font-medium text-gray-500 hover:text-gray-900">
                      Plan Your Visit
                    </Link>
                    <DropDown open={open} />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-4 bg-white px-5 py-3 sm:gap-8 sm:p-4">
                          {planYourVisit.map((item) => (
                            <Link to={item.href} key={item.name} className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                              <item.icon className="flex-shrink-0 h-6 w-6 text-us-blue" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        {/* <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-6 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <Link to={item.href} className="-m-3 p-3 flex items-center rounded-sm text-base font-medium text-gray-900 hover:bg-gray-100">
                                <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </Link>
                            </div>
                          ))}
                        </div> */}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link to="/tours" className="custom-underline text-base font-medium text-gray-500 hover:text-gray-900">
              Tours
            </Link>

            <Link to="/blog" className="custom-underline text-base font-medium text-gray-500 hover:text-gray-900">
              Blog
            </Link>
            <Link to="/contact" className="custom-underline text-base font-medium text-gray-500 hover:text-gray-900">
              Contact
            </Link>
            <Link to="/book-now">
              <button className="bg-us-blue py-2 px-3 hover:bg-us-red text-white border-solid border-2 border-grey rounded-full  transform transition duration-500 hover:scale-105">
                Book Now
              </button>
            </Link>
          </Popover.Group>
        </div>
      </nav>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <span className="sr-only">Pearl Harbor Experience</span>
                  <p className="nav-title test">
                    <div className="custom-underline">Pearl Harbor</div>
                    <div className="custom-underline">Experience</div>
                  </p>
                </div>
                <div className="">
                  <Popover.Button className="bg-white rounded-sm p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-us-red">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div>
                <div className="mt-6">
                  <div className="grid">
                    {sites.map((item) => (
                      <Link to={item.href} key={item.name} className="-m-3 p-2 flex items-center rounded-sm hover:bg-gray-50">
                        <item.icon className="flex-shrink-0 h-6 w-6 text-us-blue" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {/* <Link to="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Pricing
                </Link>

                <Link to="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Docs
                </Link> */}
                {resources.map((item) => (
                  <Link key={item.name} to={item.href} className="text-base font-medium text-gray-900 hover:text-gray-700">
                    {item.name}
                  </Link>
                ))}
              </div>
              <div>
                <Link to="/book-now" className="w-full flex items-center justify-center">
                  <button className="w-full bg-us-blue py-2 px-3 hover:bg-us-red text-white border-solid border-2 border-us-red rounded-md  transform transition duration-500 hover:scale-105">
                    Book Now
                  </button>
                </Link>
              </div>
              <div>
                {callsToAction.map((item) => (
                  <div key={item.name} className="flow-root">
                    <Link to={item.href} className="-m-3 p-3 flex items-center rounded-sm text-base font-medium text-gray-900 hover:bg-gray-100">
                      <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                      <span className="ml-3">{item.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
