import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import SchemaOrg from "./schema-org";

export type SeoObject = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  datePublished?: string;
  slug?: string;
};

export const SEO = ({ meta = {}, isBlogPost = false }: { meta: SeoObject; isBlogPost?: boolean }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            # image
            author {
              name
            }
            # organization {
            #     name
            #     url
            #     logo
            # }
            # social {
            #     twitter
            #     fbAppID
            # }
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => {
      let { title, description, image, url, datePublished, slug } = meta;

      title = title || siteMetadata.title;
      description = description || siteMetadata.description;
      image = image ? `${siteMetadata.siteUrl}${image}` : siteMetadata.image;
      url = slug ? `${siteMetadata.siteUrl}${slug}/` : `${siteMetadata.siteUrl}/`;
      datePublished = datePublished || null;

      return (
        <React.Fragment>
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
          >
            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <link rel="canonical" href={url} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="fb:app_id" content={siteMetadata?.social?.fbAppID} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={siteMetadata?.social?.twitter} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </Helmet>
          <SchemaOrg
            isBlogPost={isBlogPost}
            url={url}
            title={title}
            image={image}
            description={description}
            datePublished={datePublished}
            siteUrl={siteMetadata.siteUrl}
            author={siteMetadata.author.name}
            defaultTitle={siteMetadata.title}
          />
        </React.Fragment>
      );
    }}
  />
);

export default SEO;
