import React from "react";
import Nav from "./nav";
import Footer from "./footer";

export default function Layout({ children }) {
  return (
    <div className="page font-serif">
      <Nav />

      <main>{children}</main>
      <Footer />
    </div>
  );
}
